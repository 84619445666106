import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "tagCloud",
    style: _normalizeStyle({ width: '100%', height: `${_ctx.height}px` })
  }, [
    _createElementVNode("div", {
      ref: "chartDom",
      style: _normalizeStyle({ height: `${_ctx.height}px` })
    }, null, 4)
  ], 4))
}